function browserCheck(prefix) {
  'use strict';

  function getIEVersion() {
      var sAgent = window.navigator.userAgent;
      var Idx = sAgent.indexOf("MSIE");

      // If IE, return version number.
      if (Idx > 0)
          return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));

      // If IE 11 then look for Updated user agent string.
      else if (!!navigator.userAgent.match(/Trident\/7\./))
          return 11;

      else
          return 0; //It is not IE
  }

  function isFirefox() {
      return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  }

  function isIPhone() {
      return navigator.userAgent.toLowerCase().indexOf('iphone') > -1;

  }

  function setVariant(cl, components, body) {
      if (body && typeof (body.classList) !== 'undefined') {
          body.classList.add(cl);
      }

      Array.prototype.forEach.apply(components, [function (el) {
          if (typeof (el.classList) !== 'undefined') {
              el.classList.add(cl);
          }
      }])
  }

  var body = document.querySelector('body');

  if (!body) return;
  var components = typeof (prefix) === 'string'
      ? document.querySelectorAll('[class*="' + prefix + '"]')
      : [];

  var ie = getIEVersion();

  if (ie != 0) {
      setVariant('-ie' + ie, components, body);
  }

  if (isFirefox()) {
      setVariant('-ffx', components, body);
  }

  if (isIPhone()) {
      setVariant('-iphone', components, body);
  }
}
