function loadComponent(componentName, Fn) {
  try {
      if (typeof (window.components) === 'undefined') window.components = {};
      window.components[componentName] = [];
      var elements = document.querySelectorAll('[data-ui-component="' + componentName + '"]');

      Array.prototype.forEach.apply(elements, [function (el) {
          window.components[componentName].push(new Fn(el));
      }]);
  } catch (err) {
      console.warn(err);
  }
  return;
}
