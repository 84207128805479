function loadFont(config) {
  if (WebFont && typeof (WebFont.load) === 'function') {

      config = config || {};

      if (!config.google) config.google = { families: [] }
      if (!config.google.families) config.google.families = [];

      if (!config.custom) config.custom = { families: [], urls: [] }
      if (!config.custom.families) config.custom.families = [];
      if (!config.custom.urls) config.custom.urls = [];


      Array.prototype.forEach.apply(document.querySelectorAll('meta[property="ui:webfont:google"]'), [function (meta) {
          if (typeof (meta.content) === 'string') {
              config.google.families.push(meta.content);
          }
      }]);

      Array.prototype.forEach.apply(document.querySelectorAll('meta[property="ui:webfont:custom:family"]'), [function (meta) {
          if (typeof (meta.content) === 'string') {
              config.custom.families.push(meta.content);
          }
      }]);

      Array.prototype.forEach.apply(document.querySelectorAll('meta[property="ui:webfont:custom:url"]'), [function (meta) {
          if (typeof (meta.content) === 'string') {
              config.custom.urls.push(meta.content);
          }
      }]);

      if (!config.google.families.length) delete config.google;
      if (!config.custom.families.length || !config.custom.urls.length) delete config.custom;

      WebFont.load(config);
  }
}